import { useEffect, useState} from "react";
import { useSelector } from "react-redux";
import List from "./List";
import SlickSliderDeal from "./SlicksliderDeal";
import { fetchFavourites } from "../redux/actions/user";
import { useDispatch } from "react-redux";
import { fetchDeal } from "../redux/actions/property";

const CACHE_EXPIRY_TIME = 30 * 60 * 1000; // 30 minutes in milliseconds

const Deals = () => {
    const dispatch = useDispatch();
    const property = useSelector((state) => state.property.dealOfWeek);
    const fav = useSelector((state) => state.user.favourites);
    const { isLoggedIn, loggedInUser } = useSelector((state) => state?.user);

    const [cachedProperty, setCachedProperty] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            // Handle cache logic
            try {
                const cachedData = JSON.parse(localStorage.getItem('dealsCache'));
                const now = new Date().getTime();
    
                // Check if cached data exists, has a valid timestamp, and is still valid
                if (cachedData && cachedData.timestamp && now - cachedData.timestamp < CACHE_EXPIRY_TIME) {
                    setCachedProperty(cachedData.data);
                    return; // Exit if cache is valid
                }
            } catch (error) {
                console.error("Error reading cached data:", error);
            }
    
            // API call
            try {
                dispatch(fetchDeal());
            } catch (apiError) {
                console.error("Error fetching data from API:", apiError);
            }
        };
    
        fetchData();
    }, [dispatch]);
    

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchFavourites(loggedInUser?.id));
        }
    }, [dispatch, isLoggedIn, loggedInUser?.id]);

    useEffect(() => {
        if (property?.length > 0) {
            // Cache the property data once fetched
            localStorage.setItem(
                'dealsCache',
                JSON.stringify({
                    data: property,
                    timestamp: new Date().getTime(),
                })
            );
            setCachedProperty(property);
        }
    }, [property]);

    const displayedProperties = cachedProperty.length > 0 ? cachedProperty : property;

    return !displayedProperties?.length ? (
        <></>
    ) : (
        <div className=" 4xs:pb-4 2xl:pb-8 mt-5">
            <div className="lg:container  lg:mx-auto 2xl:pt-10 flex justify-start mx-10 pt-8 pb-3 3xl:max-w-[1500px]">
                <h3 className="text-navy font-Lexend ">
                    <span className="font-bold 3xl:pl-0 4xs:text-2xl 3xl:text-3xl">Deals</span>
                    <span className="4xs:text-2xl 3xl:text-3xl"> of the Week</span>
                </h3>
            </div>
            <div className="container mx-auto">
                <SlickSliderDeal>
                    {displayedProperties.length > 0 &&
                        fav &&
                        displayedProperties
                            .filter((p) => p.status === "Active")
                            .filter((pro) => pro.dealOfWeek === true)
                            .map(
                                (prop, index) =>
                                    index < 5 && (
                                        <List
                                            key={prop.id}
                                            property={prop}
                                            isFav={fav
                                                .map((d) => d.property_list_id)
                                                .includes(prop.id)}
                                        />
                                    )
                            )}
                </SlickSliderDeal>
            </div>
        </div>
    );
};

export default Deals;
